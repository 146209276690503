import React from "react";
import {
  Form,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  FormInput,
  Button
} from "shards-react";

import { Dispatcher, Constants, Store } from "../../flux";

export default class SearchItem extends React.Component {
  constructor(props) {
    super(props);
    this.searchRef = React.createRef();
  }

  render() {
    return (
      <Form className="main-navbar__search d-sm-flex d-md-flex d-lg-flex">
        <InputGroup seamless>
          <InputGroupAddon
            type="prepend"
            onClick={(event) => {
              this.searchRef.current.focus();
            }}>
            <InputGroupText>
              <i className="material-icons">search</i>
            </InputGroupText>
          </InputGroupAddon>
          <FormInput
            innerRef={this.searchRef}
            className="navbar-search"
            placeholder="Szukaj..."
            value={Store.getSearch()}
            onChange={(event) => {
              Dispatcher.dispatch({
                actionType: Constants.SEARCH,
                payload: event.target.value
              });
            }}
            onKeyDown={(event) => {
              if (event.keyCode === 13) {
                event.stopPropagation();
                event.preventDefault();
                event.target.blur();
              }
            }}
          />
          <InputGroupAddon type="append" >
            <Button theme="secondary" onClick={(event) => {
              Dispatcher.dispatch({
                actionType: Constants.SEARCH,
                payload: ''
              });
            }}>
              <i className="material-icons">clear</i>
            </Button>
          </InputGroupAddon>
        </InputGroup>
      </Form>
    )
  }
}
