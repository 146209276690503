/* eslint jsx-a11y/anchor-is-valid: 0 */

import React from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
} from "shards-react";
import { Store } from "../flux";
import PageTitle from "../components/common/PageTitle";
import { Link } from "react-router-dom";
import { Constants } from "../flux";
import ItemsSearch from "../components/search/ItemsSearch";

class BlogPosts extends React.Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.state = {
      Items: this.filterItems()
    };
  }

  filterItems() {
    const regexp = new RegExp(Store.getSearch(), 'i');
    return Store.getItems().filter((item) => item.title.match(regexp) || item.body.match(regexp));
  }

  componentDidMount() {
    Store.addChangeListener(Constants.SEARCH_CHANGE, this.onChange);
  }

  componentWillUnmount() {
    Store.removeChangeListener(Constants.SEARCH_CHANGE, this.onChange);
  }

  onChange() {
    this.setState({
      Items: this.filterItems()
    });
  }

  render() {
    const {
      Items,
    } = this.state;

    return (
      <Container fluid className="main-content-container px-4">

        <Row noGutters className="page-header py-4">
          <PageTitle sm="4" title="Twórczość" subtitle="" className="text-sm-left" />
        </Row>

        <Row>
          <Col lg="6" md="6" sm="12" className="mb-4">
            <ItemsSearch />
          </Col>
        </Row>

        <Row>
          {Items.map((post, idx) => (
            <Col lg="3" md="6" sm="12" className="mb-4" key={idx}>
              <Link to={`/work/${post.id}`}>
                <Card small className="card-post card-post--1">
                  <div
                    className="card-post__image"
                    style={{ backgroundImage: `url(${post.backgroundImage})` }}
                  >
                  </div>
                  <CardBody>
                    <h5 className="card-title">
                      {post.title}
                    </h5>
                    <p className="card-text d-inline-block mb-3">{post.body}</p>
                    <span className="text-muted">{post.date}</span>
                  </CardBody>
                </Card>
              </Link>
            </Col>
          ))}
        </Row>

      </Container>
    );
  }
}

export default BlogPosts;
