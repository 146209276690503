export default function() {
  return [
    {
      title: "Biografia",
      htmlBefore: '<i class="material-icons">person</i>',
      to: "/biography",
    },
    {
      title: "Twórczość",
      htmlBefore: '<i class="material-icons">vertical_split</i>',
      to: "/work",
    },
    {
      title: "Aktualności",
      to: "/news",
      htmlBefore: '<i class="material-icons">view_module</i>',  
    },

    // {
    //   title: "Add New Post",
    //   htmlBefore: '<i class="material-icons">note_add</i>',
    //   to: "/add-new-post",
    // },
    // {
    //   title: "Forms & Components",
    //   htmlBefore: '<i class="material-icons">view_module</i>',
    //   to: "/components-overview",
    // },
    // {
    //   title: "Tables",
    //   htmlBefore: '<i class="material-icons">table_chart</i>',
    //   to: "/tables",
    // },

    // {
    //   title: "Errors",
    //   htmlBefore: '<i class="material-icons">error</i>',
    //   to: "/errors",
    // }
  ];
}
