import React from "react";
import { Redirect } from "react-router-dom";

// Layout Types
import { DefaultLayout } from "./layouts";

// Route Views
import News from "./views/News";
import UserProfileLite from "./views/UserProfileLite";
// import AddNewPost from "./views/AddNewPost";
// import Errors from "./views/Errors";
// import ComponentsOverview from "./views/ComponentsOverview";
// import Tables from "./views/Tables";
import BlogPosts from "./views/BlogPosts";
import OnePostWithRouter from "./views/OnePost";

export default [
  {
    path: "/biography",
    exact: true,
    layout: DefaultLayout,
    component: UserProfileLite
  },
  {
    path: "/news",
    exact: true,
    layout: DefaultLayout,
    component: News
  },
  {
    path: "/work",
    exact: true,
    layout: DefaultLayout,
    component: BlogPosts
  },
  {
    path: "/work/:id",
    exact: true,
    layout: DefaultLayout,
    component: OnePostWithRouter
  },
  {
    path: "/",
    exact: true,
    layout: DefaultLayout,
    component: () => <Redirect to="/biography" />
  }
  // {
  //   path: "/blog-overview",
  //   layout: DefaultLayout,
  //   component: BlogOverview
  // },

  // {
  //   path: "/add-new-post",
  //   layout: DefaultLayout,
  //   component: AddNewPost
  // },
  // {
  //   path: "/errors",
  //   layout: DefaultLayout,
  //   component: Errors
  // },
  // {
  //   path: "/components-overview",
  //   layout: DefaultLayout,
  //   component: ComponentsOverview
  // },
  // {
  //   path: "/tables",
  //   layout: DefaultLayout,
  //   component: Tables
  // },

];
