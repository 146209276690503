import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Col } from "shards-react";

import SidebarMainNavbar from "./SidebarMainNavbar";
// import SidebarSearch from "./SidebarSearch";
import SidebarNavItems from "./SidebarNavItems";

import { Store, Constants } from "../../../flux";

class MainSidebar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      menuVisible: false,
      sidebarNavItems: Store.getSidebarItems()
    };

    this.onChange = this.onChange.bind(this);
  }

  componentDidMount() {
    Store.addChangeListener(Constants.CHANGE, this.onChange);
    window.addEventListener('resize', Store.closeSidebar);
  }

  componentWillUnmount() {
    Store.removeChangeListener(Constants.CHANGE, this.onChange);
    window.removeEventListener('resize', Store.closeSidebar);
  }

  onChange() {
    this.setState({
      ...this.state,
      menuVisible: Store.getMenuState(),
      sidebarNavItems: Store.getSidebarItems()
    });
  }

  render() {
    const mainClass = classNames(
      "main-sidebar",
      "px-0",
      "col-6",
      this.state.menuVisible && "open"
    );

    const backdropClass = classNames(
      "px-0",
      "col-12",
      this.state.menuVisible && "modal-backdrop fade show"
    );

    return (
      <>
        <Col
          tag="aside"
          className={mainClass}
          lg={{ size: 2 }}
          md={{ size: 3 }}
        >
          <SidebarMainNavbar hideLogoText={this.props.hideLogoText} />
          {/* <SidebarSearch /> */}
          <SidebarNavItems />
        </Col>
        <div className={backdropClass} onClick={Store.closeSidebar}>
          <div></div>
        </div>
      </>

    );
  }
}

MainSidebar.propTypes = {
  /**
   * Whether to hide the logo text, or not.
   */
  hideLogoText: PropTypes.bool
};

MainSidebar.defaultProps = {
  hideLogoText: false
};

export default MainSidebar;
