import React from "react";
import PropTypes from "prop-types";
import { Container, Row, Col } from "shards-react";

import MainNavbar from "../components/layout/MainNavbar/MainNavbar";
import MainSidebar from "../components/layout/MainSidebar/MainSidebar";
// import MainFooter from "../components/layout/MainFooter";
import { withRouter } from 'react-router-dom';
// import { Store } from "../flux";
class DefaultLayout extends React.Component {
  constructor(props) {
    super(props);
    this.unlisten = null;
  }
  componentDidMount() {
    this.unlisten = this.props.history.listen((location, action) => {
      setTimeout(() => {
        window.document.body.scrollIntoView({ block: 'start', behavior: 'smooth' });
      }, 0);
    });
  }
  componentWillUnmount() {
    this.unlisten();
  }
  render() {
    const {
      noNavbar,
      children
    } = this.props;

    return (
      <Container fluid>
        <Row>
          <MainSidebar />
          <Col
            className="main-content p-0"
            lg={{ size: 10, offset: 2 }}
            md={{ size: 9, offset: 3 }}
            sm="12"
            tag="main"
          >
            {!noNavbar && <MainNavbar />}
            {children}
            {/* {!noFooter && <MainFooter />} */}
          </Col>
        </Row>
      </Container>
    );
  }
}

DefaultLayout.propTypes = {
  /**
   * Whether to display the navbar, or not.
   */
  noNavbar: PropTypes.bool,
  /**
   * Whether to display the footer, or not.
   */
  noFooter: PropTypes.bool
};

DefaultLayout.defaultProps = {
  noNavbar: false,
  noFooter: false
};

export default withRouter(DefaultLayout);
