export default function () {
    let data = [
        {
            title: "Gdzie jesteś Boże mój - A ja wierzę w niebo wspaniałe",
            notes: "ajawierzewniebowspaniale-6166bfe381808.pdf",
            music: "gdzie_jestes_boze_moj.mp3",
            video: "https://www.youtube.com/embed/_qvOwXGWlWY",
            body: "",
        },
        {
            title: "Aniołek z obrazka",
            notes: "aniolekzobrazka-6166bfe380c50.pdf",
            music: "aniolek_z_obrazka-615d41443df3b.mp3",
            video: "",
            body: "",
        },
        {
            title: "Góry głoszą Twoją wielkość",
            notes: "gory_glosza_twoja_wielkosc_edur_gall-6166bfe384eb9.pdf",
            music: "gory_glosza_twoja_wielkosc.mp3",
            video: "https://www.youtube.com/embed/Uxxhvsv6TXA",
            body: "",
        },
        {
            title: "Niech Pan Cię poprowadzi",
            notes: "niechpanciepoprowadzi_gall-6166bfe3875c9.pdf",
            music: "niech_pan_cie_poprowadzi.mp3",
            video: "",
            body: "",
        },
        {
            title: "Niesiemy na rękach bądź w sercu dary",
            notes: "niesiemy_na_rekach_i_w_sercu_daryddur_gall-6166bfe3879b1.pdf",
            music: "niesiemy_na_rekach_badz_w_sercu_dary.mp3",
            video: "",
            body: "",
        },
        {
            title: "Ucieka ciągle rzeka",
            music: "ucieka_ciagle_rzeka-615d41443c3e3.mp3",
            notes: "",
            video: "",
            body: "",
        },
        {
            title: 'Święty sanctus',
            music: 'swietysanctus-615d41443c7cb.mp3',
            notes: "swiety_bog_zastepowcdur_gall-6166bfe38b832.mp3",
            video: "",
            body: "",
        }, {
            title: 'Trzy słowa proste nie zwyczajne',
            music: 'trzy_slowa_proste_nie_zwyczajne-615d41443cbb3.mp3',
            notes: "trzy_slowa_proste.pdf",
            video: "",
            body: "",
        }, {
            title: 'Ziemia kopalń i hut cie wykarmiła',
            music: 'ziemia_kopaln_i_hut_cie_wykarmila-615d41443cf9b.mp3',
            notes: "",
            video: "",
            body: "",
        }, {
            title: 'W świąteczny wieczór kolęda',
            music: 'w_swiateczny_wieczorkoleda-615d41443d383.mp3',
            notes: "",
            video: "",
            body: "",
        }, {
            title: 'Jestes w sile morza i fal o Panie',
            music: 'jestes_w_sile_morza_i_fal_o_panie-615d41443d76b.mp3',
            notes: "jesteswsilemorzaifal-6166bfe385689.pdf",
            video: "",
            body: "",
        }, {
            title: 'Jezu przyjdź mimo że',
            music: 'jezu_przyjdz_mimo_ze-615d41443db53.mp3',
            notes: "jezuprzyjdzmimoze-6166bfe385a71.pdf",
            video: "",
            body: "",
        }, {
            title: 'Chmury bezwodne',
            music: 'chmury_bezwodne-615d41443e70b.mp3',
            notes: "chmury_bezwodne-6166bfe382f78.pdf",
            video: "",
            body: "",
        }, {
            title: 'Czy nie chciałbyś',
            music: 'czy_nie_chcialbys-615d41443eaf3.mp3',
            notes: "",
            video: "",
            body: "",
        }, {
            title: 'Czytełem kiedyś o człowieku',
            music: 'czytelem_kiedys_o_czlowieku-615d41443eedb.mp3',
            notes: "",
            video: "",
            body: "",
        }, {
            title: 'Dzień się już kończy',
            music: 'dzien_sie_juz_konczy-615d41443f2c4.mp3',
            notes: "dzien_sie_juz_konczy_ii-6166bfe384301.pdf",
            video: "",
            body: "",
        }, {
            title: 'Dziś popatrz tam w górę',
            music: 'dzis_popatrz_tam_w_gore-615d41443f6ac.mp3',
            notes: "",
            video: "",
            body: "",
        }, {
            title: 'Follow me',
            music: 'follow_me-615d41443fa94.mp3',
            notes: "",
            video: "",
            body: "",
        }, {
            title: 'Góry głoszą Twoją wielkość',
            music: 'gory-615d414440a34.mp3',
            notes: "gory_glosza_twoja_wielkosc-gall.pdf",
            video: "",
            body: "",
        }, {
            title: 'Jesteś w sile morza i fal',
            music: 'jestes_w_sile_morza_i_fal-615d414441204.mp3',
            notes: "jesteswsilemorzaifal-6166bfe385689.pdf",
            video: "",
            body: "",
        }, {
            title: 'Juzu przyjdź mimo',
            music: 'juzu_przyjdz_mimo_ze-615d414441dbc.mp3',
            notes: "jezuprzyjdzmimoze-6166bfe385a71.pdf",
            video: "",
            body: "",
        }, {
            title: 'Mój psalm',
            music: 'moj_psalmfinal-615d4144440e5.mp3',
            notes: "mojpsalm-6166bfe386a11.pdf",
            video: "",
            body: "",
        }, {
            title: 'Najpierw zagubieni',
            music: 'najpierw_zagubieni_final-615d4144444cd.mp3',
            notes: "zagubieni-6166bfe38a892.pdf",
            video: "",
            body: "",
        }, {
            title: 'Nie miłujcie tego świata',
            music: 'nienie_milujcie_tego_swiataddur-615d4144448b5.mp3',
            notes: "nieniemilujcietegoswiata-6166bfe388181.pdf",
            video: "",
            body: "",
        }, {
            title: 'Niech Pan Cię poprowadzi',
            music: 'niech_pan_cie_poprowadzi-615d41444546d.mp3',
            notes: "niechpanciepoprowadzi_gall-6166bfe3875c9.pdf",
            video: "",
            body: "",
        }, {
            title: 'Niesiemy na rękach',
            music: 'niesiemy_na_rekach-615d414445855.mp3',
            notes: "niesiemy_na_rekach_i_w_sercu_daryddur_gall-6166bfe3879b1.pdf",
            video: "",
            body: "",
        }, {
            title: 'Odkryj Nikodem',
            music: 'odkryjnikodem-615d414446025.mp3',
            notes: "",
            video: "",
            body: "",
        }, {
            title: 'Pier Giorgio',
            music: 'piergiorgiook-615d4144467f5.mp3',
            notes: "",
            video: "",
            body: "",
        }, {
            title: 'Pędzi jak szalony',
            music: 'pedzi_jak_szalony-615d414446bdd.mp3',
            notes: "pedzi_jak_szalony-6166bfe388952.pdf",
            video: "",
            body: "",
        }, {
            title: 'Ucieka',
            music: 'ucieka-615d414446fc5.mp3',
            notes: "",
            video: "",
            body: "",
        }, {
            title: 'Wiem że Chciałbym',
            music: 'wiem_zechcialbym-615d4144473ad.mp3',
            notes: "wiem-6166bfe38a4aa.pdf",
            video: "",
            body: "",
        }, {
            title: 'Za mną',
            music: 'za_mna-615d414447b7e.mp3',
            notes: "",
            video: "",
            body: "",
        }, {
            title: 'Za trzydzieści srebrnych monet',
            music: 'za_trzydziesci_srebrnych_monet-615d414447f66.mp3',
            notes: "",
            video: "",
            body: "",
        }, {
            title: 'Ziemia kopalń',
            music: 'ziemia_kopaln_stereo-615d414448736.mp3',
            notes: "",
            video: "",
            body: "",
        }, {
            title: 'Zmroków chaosu',
            music: 'zmrokow_chaosu_z_glebin_wiecznych-615d414448f06.mp3',
            notes: "",
            video: "",
            body: "",
        },
        {
            title: 'Zostaw ślad',
            music: 'zostaw_slad-615d4144496d6.mp3',
            notes: "",
            video: "",
            body: "",
        },
        {
            title: 'Ave Maryja',
            music: '',
            notes: "ave_maryja-6166bfe381038.pdf",
            video: "",
            body: "",
        },
        {
            title: 'Ave Crux',
            music: '',
            notes: "avecrux-6166bfe381420.pdf",
            video: "",
            body: "",
        },
        {
            title: 'Będę Ci Panie',
            music: '',
            notes: "bedecipaniemarek-6166bfe381bf0.pdf",
            video: "",
            body: "",
        },
        {
            title: 'Błogosławieni ubodzy w Duchu',
            music: '',
            notes: "blogoslawieniubodzywduchu-6166bfe381fd8.pdf",
            video: "",
            body: "",
        },
        {
            title: 'Chciałbym Ci Boże',
            music: '',
            notes: "chcialbym_ci_boze-6166bfe3827a8.pdf",
            video: "",
            body: "",
        },
        {
            title: 'Do kogo mam dzisiaj',
            music: '',
            notes: "do_kogo_mam_dzisiaj-6166bfe383360.pdf",
            video: "",
            body: "",
        },
        {
            title: 'Dobrze jest grać memu Bogu',
            music: '',
            notes: "dobrzejestgracmemubogu-6166bfe383748.pdf",
            video: "",
            body: "",
        },
        {
            title: 'Do wolności',
            music: '',
            notes: "dowolnosci-6166bfe383b30.pdf",
            video: "",
            body: "",
        },
        {
            title: 'Dziękuję Ci Panie za to co mam',
            music: '',
            notes: "dziekujecipaniezatocomam-6166bfe384ad1.pdf",
            video: "",
            body: "",
        },
        {
            title: 'I tak już wiele lat',
            music: '',
            notes: "i_tak_juz_przez_wiele_lat-6166bfe3852a1.pdf",
            video: "",
            body: "",
        },
        {
            title: 'Każdy człowiek pragnie być szczęśliwym',
            music: '',
            notes: "kazdyczlowiekpragniebycszczesliwym-6166bfe385e59.pdf",
            video: "",
            body: "",
        },
        {
            title: 'Kiedy jesteś tak daleko',
            music: '',
            notes: "kiedyjestestakdaleko-6166bfe386241.pdf",
            video: "",
            body: "",
        },
        {
            title: 'Książe Pokoju',
            music: '',
            notes: "ksiaze_pokoju-6166bfe386629.pdf",
            video: "",
            body: "",
        },
        {
            title: 'Nadziejo Kościoła',
            music: '',
            notes: "nadziejokosciola-6166bfe386df9.pdf",
            video: "",
            body: "",
        },
        {
            title: 'Nie bój się Córo Syjońska',
            music: '',
            notes: "niebojsiecorosyjonska-6166bfe387d99.pdf",
            video: "",
            body: "",
        },
        {
            title: 'Pan moją mocą',
            music: '',
            notes: "panmojamocakantykmojzesza-6166bfe388569.pdf",
            video: "",
            body: "",
        },
        {
            title: 'Pokój wypełnia serce me',
            music: '',
            notes: "pokojwypelniaserceme-6166bfe388d3a.pdf",
            video: "",
            body: "",
        },
        {
            title: 'Po niebieskich polach',
            music: '',
            notes: "poniebieskichpolach-6166bfe389122.pdf",
            video: "",
            body: "",
        },
        {
            title: 'Quis ut Deus',
            music: '',
            notes: "quisutdeus-6166bfe38950a.pdf",
            video: "",
            body: "",
        },
        {
            title: 'Sadzawka Siloe',
            music: '',
            notes: "sadzawkasiloe-6166bfe3898f2.pdf",
            video: "",
            body: "",
        },
        {
            title: 'Święty Bóg zastępów',
            music: '',
            notes: "swiety_bog_zastepowcdur_gall-6166bfe38b832.pdf",
            video: "",
            body: "",
        },
        {
            title: 'Święty Stanisław',
            music: '',
            notes: "swstanislawskalko-6166bfe38b44a.pdf",
            video: "",
            body: "",
        },
        {
            title: 'W Kanie Galilejskiej',
            music: '',
            notes: "w_kanie_galilejskiej-6166bfe389cda.pdf",
            video: "",
            body: "",
        },
        {
            title: 'Wciąż raduje moje serce',
            music: '',
            notes: "wciaz_raduje_moje_serce-6166bfe38a0c2.pdf",
            video: "",
            body: "",
        },
        {
            title: 'Ziarnko gorczycy',
            music: '',
            notes: "ziarnkogorczycy-6166bfe38ac7a.pdf",
            video: "",
            body: "",
        },
    ];

    data = data.sort((first, second) => first.title.localeCompare(second.title));

    for (const index in data) {
        data[index]['id'] = index;
        data[index]['backgroundImage'] = require(`../images/content-management/${index % 19}.jpg`);
    }

    return data;

}