/* eslint jsx-a11y/anchor-is-valid: 0 */

import React from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  CardTitle
} from "shards-react";
import { Store } from "../flux";
import PageTitle from "../components/common/PageTitle";
import PdfViewer from "../components/pdf/PdfViewer";
import { withRouter, Redirect } from "react-router";
import { Link } from 'react-router-dom';

class OnePost extends React.Component {
  constructor(props) {
    super(props);
    this.getItem = this.getItem.bind(this);
    this.state = {
      post: this.getItem()
    };
  }

  getItem() {
    return Store.getItems().find((item) => item.id === this.props.match.params.id)
  }

  componentDidUpdate(prevProps) {
    if (this.props.match.params.id !== prevProps.match.params.id) {
      this.setState({
        post: this.getItem()
      });
    }
  }

  render() {
    const { post } = this.state;
    return (
      <Container fluid className="main-content-container px-4">
        {post ? (<>
          <Row noGutters className="page-header py-4">
            <PageTitle sm="12" title={post.title} subtitle="" className="text-sm-left" />
          </Row>
          <Row>
            {post.music && (
              <Col lg="6" md="6" sm="12" className="mb-4">
                <Card small className="card-post card-post--1">
                  {/* <div
                    className="card-post__image"
                    style={{ backgroundImage: `url(${post.backgroundImage})` }}
                  >
                  </div> */}
                  <CardHeader style={{ backgroundImage: 'linear-gradient(to right,rgba(0, 123, 255,.5),#ffffff)' }}>
                    <CardTitle>Muzyka</CardTitle>
                  </CardHeader>
                  <CardBody style={{ overflow: 'hidden' }}>
                    <Link to={`/media/muzyka/${post.music}`} download target="_self">
                      <h5 className="card-title">
                        <i class="material-icons">music_note</i>
                        Pobierz
                      </h5>
                    </Link>
                    <audio style={{ maxWidth: '100%', minWidth: '100%' }} controls preload="none">
                      <source src={`/media/muzyka/${post.music}`} type="audio/mp3" />
                      Your browser does not support the audio element.
                    </audio>
                  </CardBody>
                </Card>
              </Col>
            )
            }
            
            {post.video && (
              <Col lg="6" md="6" sm="12" className="mb-4">
                <Card small className="card-post card-post--1">
                  {/* <div
                    className="card-post__image"
                    style={{ backgroundImage: `url(${post.backgroundImage})` }}
                  >
                  </div> */}
                  <CardHeader style={{ backgroundImage: 'linear-gradient(to right,rgba(0, 123, 255,.5),#ffffff)' }}>
                    <CardTitle>Video</CardTitle>
                  </CardHeader>
                  <CardBody>
                    {/* <Row> */}
                    {/* <Col lg="12" md="12" sm="12" className="mb-12"> */}

                    {post.video &&
                      (<iframe
                        width="100%"
                        height="320"
                        src={post.video}
                        title="YouTube video player"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen>
                      </iframe>)
                    }
                    {/* </Col> */}
                    {/* </Row> */}
                  </CardBody>
                </Card>
              </Col>
            )
            }
            {post.notes && (
              <Col lg="6" md="6" sm="12" className="mb-4">
                <Card small className="card-post card-post--1">
                  {/* <div
                    className="card-post__image"
                    style={{ backgroundImage: `url(${post.backgroundImage})` }}
                  >
                  </div> */}
                  <CardHeader style={{ backgroundImage: 'linear-gradient(to right,rgba(0, 123, 255,.5),#ffffff)' }}>
                    <CardTitle>Nuty</CardTitle>
                  </CardHeader>
                  <CardBody style={{ overflow: 'hidden' }}>
                    <a
                      href={`/media/nuty/${post.notes}`}
                      target="_blank"
                      rel="noopener noreferrer">
                      <PdfViewer url={`/media/nuty/${post.notes}`} />
                    </a>
                  </CardBody>
                </Card>
              </Col>
            )
            }



            {/* <Col lg="6" md="6" sm="12" className="mb-4">
              <Card small className="card-post card-post--1">
                <div
                  className="card-post__image"
                  style={{ backgroundImage: `url(${post.backgroundImage})` }}
                >
                </div>
                <CardHeader style={{ backgroundImage: 'linear-gradient(to right,rgba(0, 123, 255,.5),#ffffff)' }}>
                  <CardTitle>Info</CardTitle>
                </CardHeader>
                <CardBody>
                  <Row>
                  <Col lg="12" md="12" sm="12" className="mb-12">

                  <p className="card-text d-inline-block mb-3">{post.body}</p>
                  </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col> */}
          </Row>
        </>) : (
          <Redirect
            to={{
              pathname: "/work",
            }}
          />
        )
        }
      </Container>
    );
  }
}

const OnePostWithRouter = withRouter(OnePost);
export default OnePostWithRouter;
