import React from "react";
import PropTypes from "prop-types";
import {
  Card,
  CardHeader,
  ListGroup,
  ListGroupItem
} from "shards-react";
import '../../assets/biography.css'

const UserDetails = ({ userDetails }) => (
  <Card small className="mb-4 pt-3">
    <CardHeader className="border-bottom text-center">
      <div className="mb-3 mx-auto">
        <img
          className="rounded-circle"
          src={userDetails.avatar}
          alt={userDetails.name}
          width="110"
        />
      </div>
      <h4 className="mb-0">{userDetails.name}</h4>
      <span className="text-muted d-block mb-2">{userDetails.jobTitle}</span>
    </CardHeader>
    <ListGroup flush>
      <ListGroupItem className="biography">
        <p>Urodzony 11 września 1959 roku w Nakle Śląskim. Ślązak z dziada pradziada,
          wychowany w katolickiej radzionkowskiej familii, zafascynowany genealogią swoich przodków.
        </p>
        <p>Od dzieciństwa związany z parafią świętego Wojciecha w Radzionkowie, w którym się wychował,
          uczęszczał do szkoły i gdzie posługiwał jako ministrant.
        </p>
        <p>
          Od 1977 roku animator muzyczny Ruchu Światło-Życie w parafii świętego Michała Archanioła w Suchej Górze.
          Autor muzyki i tekstów do kilkudziesięciu pieśni i piosenek o tematyce religijnej.
        </p>
        <p>
          Założyciel zespołu parafialnego Christofores odnoszącego wiele sukcesów na przeglądach piosenki religijnej:
          w 1980 roku na Mazenod Songu oraz w kolejnych latach na Sacrosongach.
          Tam też wyróżniony przez księdza biskupa Herberta Bednorza za najbogatszą własną twórczość religijną.
        </p>
        <p> W latach dziewięćdziesiątych dwukrotnie zapraszany z koncertem "Jasiu i przyjaciele" na Gaude Fest w Ustroniu
          oraz do Spodka w Katowicach. </p>

        <p>Autor muzyki do dwóch sztuk teatralnych księdza Jana Smolca wystawionych w
          kościele parafialnym w Świętochłowicach oraz w domu kultury Huty Florian. </p>

        <p>W latach 1994-2002 występował ze swoim repertuarem na Tarnogórskich Spotkaniach z Piosenką Religijną.</p>

        <p>W latach osiemdziesiątych założyciel wspólnot oazowych w Świerklańcu i Kozłowej Górze, uczestnik ewangelizacji oraz letnich rekolekcji młodzieżowych.</p>

        <p>W późniejszych latach członek Domowego Kościoła oraz współorganizator letnich rekolekcji Oazy Dzieci Bożych w diecezji
        gliwickiej. </p>
        
        <p>Absolwent Akademii Wychowania Fizycznego w Katowicach na kierunku rehabilitacja ruchowa.</p>

        <p>Nauczyciel elektroniki w Zespole Szkół Górniczych w Bytomiu-Szombierkach, Zespole Szkół Mechaniczno-Elektronicznych
        w Radzionkowie, nauczyciel wychowania fizycznego w Szkole Podstawowej nr 1 w Radzionkowie, I Liceum Ogólnokształcącym
        w Tarnowskich Górach oraz Szkole Muzycznej w Bytomiu. Pedagog lubiany i ceniony przez swoich uczniów. </p>

        <p>Wieloletni organista w parafii świętego Stanisława Biskupa i Męczennika w Bytomiu oraz autor felietonów
        "Szalonego organisty" w gazetce parafialnej. </p>
        
        <p>Odznaczony Nagrodą Honorową "Crux Adalberti" za wieloletnią pracę pedagogiczną,
        umacnianie wśród uczniów poczucia tożsamości i przekazywanie lokalnych tradycji i obyczajów. 
        </p>

        <p>
        Mocno zaangażowany w
        odzyskanie praw miejskich przez Radzionków.</p> 
        
        <p>Od 1996 roku przez kolejnych siedem lat publikował felietony w lokalnym
        czasopiśmie "Radzińczanin" pod zabawnym pseudonimem Erwin Keta. Był bardzo zaangażowany w organizację i
        prowadzenie pierwszych festynów parafialnych Cider Fest oraz Fyrtka - Międzynarodowego Festiwalu Folklorystycznego.
        </p>

        <p>
        Muzyczny samouk grający na gitarze, akordeonie i klawiszach. Człowiek wielu pasji oraz talentów.
        </p>
        <p> Ukochany mąż, troskliwy ojciec oraz wspaniały dziadek. Zmarł nagle 28 września 2020 roku w Bytomiu-Suchej Górze.</p>
      </ListGroupItem>
    </ListGroup>
  </Card>
);

UserDetails.propTypes = {
  /**
   * The user details object.
   */
  userDetails: PropTypes.object
};

UserDetails.defaultProps = {
  userDetails: {
    name: "Jan Michalik",
    avatar: require("./../../images/avatars/jasiu.jpg"),
    jobTitle: "",
    performanceReportTitle: "Workload",
    performanceReportValue: 74,
    metaTitle: "Description",
  }
};

export default UserDetails;
