import React from "react";
import { Container, Row, Col, Card } from "shards-react";
import PageTitle from "./../components/common/PageTitle";

const News = () => (
  <Container fluid className="main-content-container px-4">
   
    <Row noGutters className="page-header py-4">
      <PageTitle title="Aktualności" className="text-sm-left mb-3" />
    </Row>

    <Card small className="mb-4 pt-3">
    <Row>
      <Col lg="12" md="12" sm="12" className="mb-4">
        <div style={{display: "grid", height:"100%"}}>
        <img
          src={require("./../images/koncert_jan_michalik.jpg")}
          alt="Plakat"
          style={{maxWidth: "100%", maxHeight:"calc(100vh - 120px)", margin: "auto"}}
        />
        </div>

      </Col>
    </Row>
    </Card>
  </Container>
);


export default News;
