import React from "react";
import PropTypes from "prop-types";
import { Document, Page, pdfjs } from "react-pdf";
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import '../../assets/pdf.css'
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const PdfViewer = ({ url }) => (
  <div className="pdfsite">
    <div className="pdfsite__container">
      <div className="pdfsite__container__document">
        <Document
          file={url}
        >
          <Page
            pageNumber={1}
          />
        </Document>
      </div>
    </div>
  </div>
)

PdfViewer.propTypes = {
  url: PropTypes.string
};

// PdfViewer.defaultProps = {
//   url: ""
// };

export default PdfViewer;